import React, { Component } from 'react'

import CyclePage from '../../containers/CyclePage'
import PrimaryLayout from '../../components/layouts/PrimaryLayout'
import pagesInfos from '../../config/i18n/pagesInfos'

const CycleHiver20212022 = ({ pageContext }) => {
  return (
    <PrimaryLayout
      language={pageContext.language}
      isDisplayFromHome={false}
      page={pagesInfos.ComponentCyclesEtStagesCycleHiver20212022}
    >
      <CyclePage cycle="hiver-2021-2022" />
    </PrimaryLayout>
  )
}

export default CycleHiver20212022